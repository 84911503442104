document.addEventListener('DOMContentLoaded', () => {
  let $tabs = $('.tabs')
  let $buttons = $('li a', $tabs)

  $buttons.click(function(){
    $('li', $tabs).removeClass('is-active')
    $(this).parent().addClass('is-active')

    showTabContent($(this))
  })

  let $cards = $('.tab-content .card[data-id]')

  function showTabContent($activeTab) {
    var tabId = $activeTab.data('id')
    $cards.map(function(idx, el){
      var ids = $(el).data('id') || []

      if (ids.indexOf(tabId) >= 0) {
        $(el).fadeIn()
      } else {
        $(el).fadeOut()
      }
    })
  }

  // set default active tab ('всё')
  $buttons.first().click()
})

