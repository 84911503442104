import { TweenMax, ScrollToPlugin} from 'gsap/all'

const plugins = [ ScrollToPlugin ];

(function(){
  /**
   * помощник для "мягкой" анимированной
   * прокрутки к элементу на странице
   *
   * @requires TweenMax
   *
   * Элементы-кнпоки должны быть размечены классом `.scroll-to`,
   * цель прокрутки - это идентификатор элемента указанный
   * в атрибуте `data-target`.
   *
   * Кнопка
   *
   * ```html
   * a.navbar-item.scroll-to data-target="my-section"
   * ```
   * целевой элемент
   *
   * ```html
   * section#my-section
   * ```
   *
   */
  document.addEventListener('DOMContentLoaded', start)
  function start(){
    $('.scroll-to').click(function(){
      var target = '#' + $(this).data('target')
      var $target = $(target)

      if (target === '#') return
      if ($target.length === 0) return

      TweenMax.to(window, 1, {
        scrollTo: {y: target, offsetY: 50}
      })
    })
  }
})();
