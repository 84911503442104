/* eslint no-console:0 */

import 'core-js/stable'
import "regenerator-runtime/runtime"

require('../src/ui/tabs')
require('../src/ui/scrollup')
require('../src/ui/scroll')
require('../src/ui/sticky')
require('../src/media/colorbox')
require('../src/animations/index')
require('../src/ui/navbar')