// magnific-popup (alternative for colorbox)
// require('magnific-popup/dist/jquery.magnific-popup')
// require('magnific-popup/dist/magnific-popup.css')

// colorbox
require('jquery-colorbox/jquery.colorbox')
require('jquery-colorbox/example5/colorbox.css')

function start(timeout) {
  setTimeout(function(){
    $('a.colorbox').colorbox({
      maxWidth: "90%",
      maxHeight: "90%"
    })
  }, timeout || 100)
}

document.addEventListener('DOMContentLoaded', start)
