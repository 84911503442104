import {TweenMax, ScrollToPlugin } from 'gsap/all'

const plugins = [ ScrollToPlugin ];

(function(){
  document.addEventListener('DOMContentLoaded', start)
  function start(){
    // кнопка в views/layouts/application.html.slim
    let $scrollup = $('#scroll-up')
    if ($scrollup.length !== 1) return
    $scrollup.click(function(){
      TweenMax.to(window, 1, {
        scrollTo: 0
      })
    })
    window.onscroll = function() {
      if (window.pageYOffset > document.documentElement.clientHeight)
        $scrollup.css("opacity", "1");
      else
        $scrollup.css("opacity", "0");
    }
  }
})()
