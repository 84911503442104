(function(){
  document.addEventListener('DOMContentLoaded', start)
  function start(){
    // bulma navbar
    $('a.navbar-burger').click(function(e){
      e.stopPropagation()
      e.preventDefault()
      $(this).parents('.navbar').find('.navbar-menu').toggleClass('is-active')
    })

    $('body').click(function(){
      $('body').find('.navbar-menu').removeClass('is-active')
    })
  }
})()
