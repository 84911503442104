import { TweenMax, ScrollToPlugin } from 'gsap/all'
import ScrollMagic from 'scrollmagic'

const plugins = [ ScrollToPlugin ];

(function(){
  document.addEventListener('DOMContentLoaded', start)
  const DEBUG = false
  function start(){
    let selector = '.navbar-top'
    let navbar = document.querySelector(selector)

    if (!navbar) return

    var controller = new ScrollMagic.Controller({})
    var menuSceneOffset = 450
    var menuScene =  new ScrollMagic.Scene({
      triggerElement: '.navbar-top',
      triggerHook: 'onLeave',
      offset: menuSceneOffset,
      duration: 0
    })
      .setClassToggle('.navbar-top', 'is-pinned')
      .setPin('.navbar-top')
      .on('enter', function(e){
        TweenMax.to('.navbar-top', .3, {
          y: menuSceneOffset,
          zIndex: 1
        })
      })
      .on('leave', function(e){
        TweenMax.to('.navbar-top', .2, {
          y: 0
        })
      })

    if(DEBUG) {
      menuScene.addIndicators({
        name: 'topmenu',
        colorStart: 'red',
        colorTrigger: 'green',
        indent: menuSceneOffset
      })
    }

    setTimeout(function(){
      menuScene.addTo(controller)
    }, 100)
  }
})()
