import { TweenMax, CSSPlugin } from 'gsap/all'

const plugins = [ CSSPlugin ];

document.addEventListener('DOMContentLoaded', () => {
  $('.profit-icon').hover(function(){
    TweenMax.to(this, .3, { scale: 1.2})
  }, function(){
    TweenMax.to(this, .5, { scale: 1})
  })
})
